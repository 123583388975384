import { __decorate } from "tslib";
import FinanceiroAlunoComponent from '@/components/FinanceiroAlunoComponent.vue';
import FinanceiroValorPagoTextComponent from '@/components/FinanceiroValorPagoTextComponent.vue';
import PaymentModal from '@/components/PaymentComponent.vue';
import { Component, Vue } from 'vue-property-decorator';
import { atHomeStore, mainStore } from '@/utils/store-accessor';
let MeuFinanceiroList = class MeuFinanceiroList extends Vue {
    constructor() {
        super(...arguments);
        this.items = [];
        this.dialog = false;
        this.selectedItem = null;
    }
    async showPix(item) {
        window.open(item.fatura_url, '_blank');
        //   // @ts-ignore
        //   const form: any = this.$refs.PixIntegradorPagamentoDialog;
        //   form.show(item);
    }
    faturaEmAtraso(item) {
        const hoje = new Date();
        return new Date(item.vencimento) < hoje;
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    async created() {
        this.items = await atHomeStore.getAtHomeFinanceiro();
    }
    openPaymentModal(item) {
        this.selectedItem = item;
        this.dialog = true; // Abre o modal de pagamento
    }
    confirmPayment() {
        this.dialog = false; // Fecha o modal após o pagamento
    }
};
MeuFinanceiroList = __decorate([
    Component({
        components: {
            FinanceiroAlunoComponent,
            FinanceiroValorPagoTextComponent,
            PaymentModal,
        },
    })
], MeuFinanceiroList);
export default MeuFinanceiroList;
