<template>
  <v-app>
    <!-- Botão para abrir o modal -->
    <v-btn
      color="primary"
      @click="dialog = true"
    >Pagar Agora</v-btn>

    <!-- Modal -->
    <v-dialog
      v-model="dialog"
      :max-width="800"
      persistent
    >
      <v-card class="rounded-card">
        <v-card-title>
          <span class="headline">Selecione o Método de Pagamento</span>
        </v-card-title>

        <v-card-text>
          <v-row>
            <!-- Coluna Esquerda: Opções de Método de Pagamento -->
            <v-col cols="4">
              <v-list dense>
                <v-list-item
                  v-for="(item, index) in paymentMethods"
                  :key="index"
                  @click="selectPaymentMethod(item)"
                  :class="{ 'selected-item': currentMethod === item.name }"
                >
                  <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>

            <!-- Coluna Direita: Conteúdo do Método de Pagamento Selecionado -->
            <v-col cols="8">
              <div v-if="currentMethod === 'Cartão de Crédito'">
                <!-- Exibição visual do cartão de crédito -->
                <v-card class="credit-card-display">
                  <div
                    class="card"
                    :class="{ flipped: isFlipped }"
                  >
                    <div class="card-front">
                      <div class="card-details">
                        <div class="card-number">{{ cardNumberMasked }}</div>
                        <div class="card-holder">
                          <span>Card Holder</span>
                          <span>{{ cardHolder || "SEU NOME" }}</span>
                        </div>
                        <div class="card-expiration">
                          <span>Expires</span>
                          <span>{{ cardExpiration }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="card-back">
                      <div class="cvv-field">
                        <span>CVV</span>
                        <div class="cvv-value">{{ cardCVV || "***" }}</div>
                      </div>
                    </div>
                  </div>
                </v-card>

                <!-- Formulário de Cartão de Crédito -->
                <v-form ref="creditCardForm">
                  <v-text-field
                    label="Número do Cartão"
                    v-model="cardNumberFormatted"
                    maxlength="19"
                    @focus="isFlipped = false"
                    @keypress="onlyNumber"
                    @paste="onCardNumberPaste"
                  ></v-text-field>

                  <v-text-field
                    label="Nome do Titular"
                    v-model="cardHolder"
                    maxlength="30"
                    @focus="isFlipped = false"
                    @keypress="onlyLetters"
                    @paste="onCardHolderPaste"
                  ></v-text-field>

                  <v-row>
                    <v-col>
                      <v-select
                        :items="months"
                        label="Mês de Expiração"
                        v-model="cardMonth"
                        @focus="isFlipped = false"
                      ></v-select>
                    </v-col>
                    <v-col>
                      <v-select
                        :items="years"
                        label="Ano de Expiração"
                        v-model="cardYear"
                        @focus="isFlipped = false"
                      ></v-select>
                    </v-col>
                  </v-row>

                  <v-text-field
                    label="CVV"
                    v-model="cardCVV"
                    maxlength="4"
                    @focus="isFlipped = true"
                    @blur="isFlipped = false"
                    @input="cardCVV = cardCVV.replace(/\D/g, '').slice(0, 4)"
                  ></v-text-field>

                  <v-btn
                    color="primary"
                    @click="submitPayment"
                  >Pagar</v-btn>
                </v-form>
              </div>

              <div v-else-if="currentMethod === 'PIX'">
                <!-- Conteúdo do PIX -->
                <v-card class="px-5 py-3">
                  <h3>Pagamento via PIX</h3>
                  <p>Escaneie o QR Code abaixo para realizar o pagamento.</p>
                  <!-- Inserir um componente de QR Code ou imagem -->
                  <v-img
                    src="path_to_your_qrcode_image.png"
                    max-width="200"
                  ></v-img>

                  <!-- Botão para confirmar o pagamento via PIX -->
                  <v-btn
                    color="primary"
                    @click="submitPayment"
                  >Pagar</v-btn>
                </v-card>
              </div>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            text
            @click="dialog = false"
          >Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      dialog: true, // O modal inicia aberto
      currentMethod: 'Cartão de Crédito', // Método de pagamento inicial
      paymentMethods: [
        { name: 'Cartão de Crédito', icon: 'mdi-credit-card' },
        { name: 'PIX', icon: 'mdi-qrcode' }
      ],
      // Dados do cartão de crédito
      cardNumber: '',
      cardHolder: '',
      cardMonth: '',
      cardYear: '',
      cardCVV: '',
      isFlipped: false,
      months: Array.from({ length: 12 }, (_, i) =>
        (i + 1).toString().padStart(2, '0')
      ),
      years: Array.from({ length: 10 }, (_, i) =>
        (new Date().getFullYear() + i).toString()
      )
    };
  },
  computed: {
    // Computed properties para o cartão de crédito
    cardNumberFormatted: {
      get() {
        const numero = this.cardNumber.replace(/\D/g, '').slice(0, 16);
        const formattedNumber = numero.replace(/(\d{4})(?=\d)/g, '$1 ').trim();
        return formattedNumber;
      },
      set(value) {
        this.cardNumber = value.replace(/\D/g, '').slice(0, 16);
      }
    },
    cardNumberMasked() {
      return this.cardNumberFormatted || '#### #### #### ####';
    },
    cardExpiration() {
      if (this.cardMonth && this.cardYear) {
        return `${this.cardMonth}/${this.cardYear.slice(2)}`;
      }
      return 'MM/AA';
    }
  },
  methods: {
    selectPaymentMethod(item) {
      this.currentMethod = item.name;
    },
    // Métodos para o cartão de crédito
    onlyLetters(event) {
      const key = event.key;
      // Permite apenas letras e espaços
      if (!key.match(/[a-zA-Z\s]/) && key.length === 1) {
        event.preventDefault();
      }
    },
    onlyNumber(event) {
      const key = event.key;
      // Permite apenas números
      if (!key.match(/[0-9]/) && key.length === 1) {
        event.preventDefault();
      }
    },
    onCardNumberPaste(event) {
      const paste = (event.clipboardData || window.clipboardData).getData(
        'text'
      );
      // Remove espaços e verifica se há apenas números
      if (!/^\d+$/.test(paste.replace(/\s/g, ''))) {
        event.preventDefault();
      }
    },
    onCardHolderPaste(event) {
      const paste = (event.clipboardData || window.clipboardData).getData(
        'text'
      );
      // Verifica se há apenas letras e espaços
      if (!/^[A-Za-z\s]+$/.test(paste)) {
        event.preventDefault();
      }
    },
    submitPayment() {
      if (this.currentMethod === 'Cartão de Crédito') {
        // Implementar lógica de submissão do pagamento com cartão de crédito
        console.log('Pagamento com cartão de crédito enviado');
        // Você pode adicionar validações aqui
      } else if (this.currentMethod === 'PIX') {
        // Implementar lógica de submissão do pagamento via PIX
        console.log('Pagamento via PIX iniciado');
        // Você pode adicionar ações específicas para o PIX aqui
      }
      // Fechar o modal após o pagamento
      this.dialog = false;
    }
  }
};
</script>

<style scoped>
.selected-item {
  background-color: #e0e0e0;
}

/* Estilos do modal */
.rounded-card {
  border-radius: 20px; /* Aumenta o arredondamento dos cantos */
}

/* Estilos do cartão de crédito */
.credit-card-display {
  width: 100%;
  max-width: 400px;
  height: 250px;
  margin-bottom: 30px;
  perspective: 1000px;
}

.card {
  width: 100%;
  height: 100%;
  position: relative;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.card.flipped {
  transform: rotateY(180deg);
}

.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.card-front {
  background-color: #2b2b2b;
  color: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-back {
  background-color: #1a1a1a;
  color: #fff;
  transform: rotateY(180deg);
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.card-number {
  font-size: 1.5em;
  letter-spacing: 2px;
}

.card-holder,
.card-expiration {
  font-size: 0.9em;
  display: flex;
  justify-content: space-between;
}

.card-holder span,
.card-expiration span {
  display: block;
}

.cvv-field {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cvv-value {
  background-color: #fff;
  color: #000;
  padding: 5px 10px;
  border-radius: 5px;
  margin-top: 10px;
}
</style>
